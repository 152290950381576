import {Link} from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Termini di Sevizio" />
    <div className="policy container p-5">

      <p className="p1"><span className="s1"><strong>TERMINI E CONDIZIONI DI UTILIZZO PER L&rsquo;UTENTE</strong></span></p>
      <p className="p2"><br/></p>
      <p className="p3"><span
        className="s2"><strong>Condizioni e Termini di utilizzo dell&rsquo;App &ldquo;Mio Pediatra&rdquo;</strong> (di seguito, &ldquo;App&rdquo;)<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p4"><br/></p>
      <p className="p5"><span className="s1">Procedura di registrazione e creazione account</span></p>
      <p className="p4"><br/></p>
      <p className="p3"><span
        className="s2">1.1 Il presente documento di termini e condizioni di utilizzo (di seguito, &ldquo;Termini e Condizioni&rdquo;) disciplina l&rsquo;accesso e l&rsquo;uso, da parte dell&rsquo;utente (di seguito l&rsquo; &ldquo;Utente&rdquo;), dell&rsquo;App &ldquo;Mio Pediatra&rdquo; di propriet&agrave; di Mio Pediatra S.r.l., con sede legale in VIA GIACOMO MEDICI 9/A 35138 PADOVA (PD) (di seguito &ldquo;MioPediatra&rdquo; o<span
        className="Apple-converted-space">&nbsp;&nbsp;</span>&ldquo;Societ&agrave;&rdquo; e, congiuntamente all&rsquo;Utente, &ldquo;Parti&rdquo;).</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span
        className="s2">1.2 La registrazione e l&rsquo;attivazione dell&rsquo;account sull&rsquo;App (di seguito l&rsquo;&ldquo;Account&rdquo;) costituiscono condizione essenziale e necessaria affinch&eacute; l&rsquo;Utente possa utilizzare e beneficiare dei Servizi (come di seguito definiti) erogati da MIOPEDIATRA tramite l&rsquo;App.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">I Servizi e le modalit&agrave; di esecuzione</span></p>
      <p className="p4"><br/></p>
      <p className="p3"><span className="s2">2.1 MIOPEDIATRA &egrave; titolare dell&rsquo;App attraverso il quale agli Utenti e i Professionisti possono comunicare a distanza (di seguito &ldquo;Servizi&rdquo;).</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">2.2 Per poter beneficiare dei Servizi, l&rsquo;Utente deve registrarsi e mantenere attivo e aggiornato l&rsquo;Account.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">2.3 L&rsquo;Utente che intenda richiedere l&rsquo;erogazione dei Servizi, deve accedere al proprio Account inserendo le proprie credenziali email e password.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span
        className="s2">2.4 MIOPEDIATRA potr&agrave; modificare e aggiornare periodicamente l&rsquo;App, inclusa la configurazione, il layout, i contenuti e le funzionalit&agrave;, senza alcun preavviso.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Obblighi dell&rsquo;Utente<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p3"><span className="s2">3.1 Nel richiedere l&rsquo;erogazione dei Servizi, l&rsquo;Utente si obbliga a:</span>
      </p>
      <ol className="ol1">
        <li className="li3"><span className="s2">non utilizzare e fornire dati, informazioni o documenti falsi o non aggiornati;</span>
        </li>
        <li className="li3"><span className="s2">non creare pi&ugrave; di un Account;</span></li>
        <li className="li3"><span
          className="s2">non creare un altro Account (anche con un altro nome) quando il proprio &egrave; gi&agrave; stato disabilitato da MIOPEDIATRA;</span>
        </li>
        <li className="li3"><span className="s2">non condividere il proprio Account e la propria password con terzi o compiere qualsiasi altra azione che potrebbe mettere a rischio la sicurezza del medesimo;</span>
        </li>
        <li className="li3"><span className="s2">notiziare immediatamente MIOPEDIATRA, anche in caso di semplice sospetto, che l&rsquo;Account sia stato violato o i relativi dati non siano pi&ugrave; riservati o confidenziali;</span>
        </li>
        <li className="li3"><span className="s2">mantenere le informazioni contenute nell&rsquo;Account sempre accurate, complete e aggiornate. L&rsquo;inosservanza di tale obbligo pu&ograve; determinare l&rsquo;impossibilit&agrave; di accedere e utilizzare i Servizi.</span>
        </li>
      </ol>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">3.3 Per poter richiedere i Servizi tramite l&rsquo;App, l&rsquo;Utente deve essere in possesso di smartphone o tablet o pc e disporre di una connessione internet. I costi del consumo dei dati sono a carico dell&rsquo;Utente.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p2"><span className="s2"><span className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Obblighi di MIOPEDIATRA</span></p>
      <p className="p3"><span className="s2">4.1 Nell&rsquo;erogazione dei Servizi, MIOPEDIATRA si impegna a:</span></p>
      <ol className="ol1">
        <li className="li3"><span
          className="s2">gestire l&rsquo;App sia da un punto di vista tecnico che operativo;</span></li>
        <li className="li3"><span
          className="s2">fornire il supporto necessario per la creazione e gestione dell&rsquo;Account;</span></li>
        <li className="li3"><span className="s2">ripristinare nell&rsquo;immediato le funzionalit&agrave; dell&rsquo;App in caso di eventuale lock down.</span>
        </li>
      </ol>
      <p className="p2"><br/></p>
      <p className="p6"><span className="s1">Durata<span className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p3"><span className="s2">5.1 I presenti Termini e Condizioni hanno una durata corrispondente al tempo intercorrente tra l&rsquo;attivazione dell&rsquo;Account e la sua disattivazione, per qualsiasi ragione essa avvenga.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Disattivazione e cancellazione dell&rsquo;Account</span></p>
      <p className="p3"><span
        className="s2">6.1 MIOPEDIATRA si riserva il diritto di disattivare l&rsquo;Account dell&rsquo;Utente qualora quest&rsquo;ultimo:</span>
      </p>
      <ol className="ol2">
        <li className="li3"><span className="s2">violi le previsioni contenute nei presenti Termini e Condizioni;</span>
        </li>
        <li className="li3"><span className="s2">compia atti o fatti che causino pregiudizio a MIOPEDIATRA o al Professionista;</span>
        </li>
        <li className="li3"><span
          className="s2">utilizzi le funzionalit&agrave; dell&rsquo;App per finalit&agrave; illecite, ingannevoli o in ogni caso in violazione di qualsiasi legge applicabile e/oi qualsiasi diritto di terzi;</span>
        </li>
        <li className="li3"><span className="s2">intraprenda azioni che possano impedire, sovraccaricare e compromettere il corretto funzionamento o l&rsquo;aspetto dell&rsquo;App;</span>
        </li>
        <li className="li3"><span className="s2">tenti di ottenere informazioni di accesso o di accedere agli account di altri utenti;</span>
        </li>
        <li className="li3"><span className="s2">incoraggi altri Utenti a non osservare le regole di MIOPEDIATRA.</span>
        </li>
      </ol>
      <p className="p7"><br/></p>
      <p className="p3"><span className="s2">6.2 MIOPEDIATRA collaborer&agrave; con le autorit&agrave; dando pronto adempimento ai provvedimenti volti all&rsquo;identificazione di chi compie attivit&agrave; illecite tramite l&rsquo;App.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">6.3 L&rsquo;Utente potr&agrave; richiedere a MIOPEDIATRA, in qualunque momento, la cancellazione del proprio Account inviando la relativa richiesta via email all&rsquo;indirizzo unsubscribe@miopediatra.com</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Responsabilit&agrave; dell&rsquo;Utente<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p3"><span className="s2">7.1 L&rsquo;Utente &egrave; direttamente ed esclusivamente responsabile di tutti i contenuti, dati e informazioni che fornisce a MIOPEDIATRA e al Professionista in fase di registrazione, di richiesta e di espletamento dei Servizi.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Limitazione di responsabilit&agrave; di MIOPEDIATRA<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p3"><span className="s2">8.1 MIOPEDIATRA (inclusi gli amministratori, i dipendenti, i collaboratori) non sar&agrave; in alcun modo responsabile a nessun titolo di azioni od omissioni da parte degli Utenti, delle informazioni fornite dagli Utenti in fase di registrazione, dei contenuti, delle informazioni o dei dati (anche di terzi) forniti dagli Utenti tramite l&rsquo;App nonch&eacute; per eventuali danni, diretti o indiretti, sofferti dagli Utenti o da terzi in conseguenza della consultazione dell&rsquo;App e/o dell&rsquo;utilizzo delle sue varie funzionalit&agrave;.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">8.2 MIOPEDIATRA non fornisce alcuna prestazione medico-sanitaria o di assistenza e, pertanto, non pu&ograve; essere in alcun modo considerata responsabile di qualsiasi atto o fatto doloso o colposo del Professionista, che possa causare danni diretti o indiretti ai pazienti che hanno ricevuto i Servizi.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Gratuit&agrave; del servizio</span></p>
      <p className="p3"><span
        className="s2">9.1 L&rsquo;erogazione dei Servizi attualmente forniti a mezzo App &egrave; gratuita e non richiede versamento di corrispettivo alcuno.<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">9.2 Potranno essere implementati successivamente Servizi a pagamento, dei quali sar&agrave; data pronta ed immediata comunicazione agli Utenti.<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">9.3 In ogni caso il prezzo dei servizi sar&agrave; opportunamente indicato per iscritto.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">9.4 MIOPEDIATRA si riserva il diritto di variare l&rsquo;ammontare dei corrispettivi di cui al presente articolo nel rispetto del fair market value, informando tempestivamente l&rsquo;Utente, il quale avr&agrave; piena libert&agrave; di accettare o meno le nuove condizioni economiche.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">9.5 MIOPEDIATRA si riserva di offrire ai propri clienti promozioni e scontistiche esclusive che verranno comunicate tramite l&rsquo;App e mediante notifiche al numero di telefono o email indicati in fase di registrazione, previo espresso consenso dell&rsquo;Utente.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Divieto di cessione</span></p>
      <p className="p3"><span className="s2">10.1 L&rsquo;Utente accetta sin da ora che MIOPEDIATRA possa cedere i presenti Termini e Condizioni (anche in caso di cessione di azienda o di ramo di azienda), valendo tale accettazione come preventivo consenso alla cessione, purch&eacute; vengano ceduti agli stessi termini e condizioni o a termini e condizioni che non siano meno vantaggiosi per l&rsquo;Utente. Tale cessione, con le relative modalit&agrave; di esecuzione, dovr&agrave; essere previamente comunicata all&rsquo;Utente all&rsquo;indirizzo email comunicato in fase di registrazione.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">10.2 &Egrave; fatto divieto all&rsquo;Utente di cedere i presenti Termini e Condizioni.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">10.3 In caso di violazione da parte dell&rsquo;Utente del divieto di cessione, la cessione si intender&agrave; come non avvenuta e quindi priva di effetti nei rapporti con MIOPEDIATRA, che avr&agrave; la facolt&agrave; di ritenere risolti i presenti Termini e Condizioni.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Propriet&agrave; industriale<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p3"><span className="s2">11.1 I marchi, i disegni, la grafica, i nomi, i nomi a dominio e gli altri segni distintivi e opere creative di qualsiasi genere utilizzati nell&rsquo;App sono di propriet&agrave; e titolarit&agrave; esclusiva di MIOPEDIATRA. Il loro uso da parte dell&rsquo;Utente &egrave; espressamente vietato, in qualsiasi modo ed a qualsiasi scopo, ivi inclusi, a mero titolo esemplificativo, l&rsquo;utilizzo, la pubblicazione, la copia e la riproduzione anche su altri siti web o app, senza la preventiva autorizzazione scritta di MIOPEDIATRA.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">11.2 Tutti i contenuti, quali, a mero titolo esemplificativo, informazioni, programmi software, testo, grafica, icone ed immagini, presenti all&rsquo;interno dell&rsquo;App sono protetti dal diritto d&rsquo;autore. All&rsquo;Utente &egrave; pertanto espressamente vietato in qualsiasi momento modificare, distribuire, trasmettere, divulgare, pubblicare, commercializzare, concedere in licenza, copiare o utilizzare i contenuti per scopi commerciali o comunque per scopi diversi da quelli contenuti nei presenti Termini e Condizioni.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Forza maggiore<span className="Apple-converted-space">&nbsp;</span></span>
      </p>
      <p className="p3"><span className="s2">12.1 MIOPEDIATRA non sar&agrave; responsabile per l&rsquo;inadempimento delle proprie obbligazioni qualora questo sia dovuto a eventi che siano estranei al suo controllo, includendo, a mero titolo esemplificativo, pandemie, guerre, inondazioni, cataclismi e qualsiasi altra causa che MIOPEDIATRA non abbia la possibilit&agrave; di prevenire usando la normale diligenza.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Trattamento dei dati personali<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p3"><span className="s2">13.1 Per maggiori informazioni sul trattamento dei dati personali relativi alla registrazione e alla creazione del proprio Account, si invita l&rsquo;Utente a consultare l&rsquo;<Link to={"/privacy-policy"}>Informativa Privacy</Link>.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Disposizioni finali<span
        className="Apple-converted-space">&nbsp;</span></span></p>
      <p className="p3"><span className="s2">14.1 MIOPEDIATRA si riserva il diritto di modificare, in tutto o in parte, i Termini e Condizioni, a sua discrezione, fornendone comunicazione tempestiva all&rsquo;Utente.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">14.2 Eventuali deroghe ai Termini e Condizioni non saranno valide se non concordate tra le Parti e stipulate in forma scritta.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">14.3 La nullit&agrave; di singole clausole o di parti di esse non comporta la nullit&agrave; dell&rsquo;interezza dei presenti Termini e Condizioni.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">14.4 I titoli degli articoli dei presenti Termini e Condizioni hanno un mero scopo indicativo e in nessun modo limitano o descrivono il significato e il contenuto del relativo articolo.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p5"><span className="s1">Legge applicabile e foro competente</span></p>
      <p className="p3"><span className="s2">15.1 I presenti Termini e Condizioni sono regolati e interpretati ai sensi della legge italiana.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">15.2 Qualsiasi controversia derivante dall&rsquo;applicazione dei presenti Termini e Condizioni sar&agrave; devoluto alla competenza esclusiva del Foro di Padova, fatti salvi i diritti degli Utenti di adire il foro di propria competenza.</span>
      </p>
      <p className="p2"><br/></p>
      <p className="p1"><span className="s2">***</span></p>
      <p className="p2"><br/></p>
      <p className="p3"><span className="s2">Ai sensi e per gli effetti di cui agli artt. 1341 e 1342 cod. civ., dichiari di aver letto, compreso e accettato espressamente le seguenti previsioni dei presenti Termini e Condizioni: Obblighi dell&rsquo;Utente, Disattivazione e cancellazione dell&rsquo;Account, Responsabilit&agrave; dell&rsquo;Utente, Limitazione di responsabilit&agrave; di MIOPEDIATRA, Divieto di cessione, Propriet&agrave; industriale, Legge applicabile e foro competente.</span>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
